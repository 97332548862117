@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@font-face {
  font-family: Sansation;
  src: url('./font/Sansation_Light.ttf');
}
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  padding: 0px;
  margin: 0px;
}

.gradient {
  background: linear-gradient(25deg, #11274C 35.83%, rgba(17, 39, 76, 0.00) 105.95%);
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 0px #000000 !important;
}

.label {
  transition: font-size 0.2s ease;
}


.input:focus ~ .label,
.input:not(:placeholder-shown).input:not(:focus)  ~.label {
  font-size: 12px !important;
  top: -1px;
}

.shadow{
  box-shadow: 4.744px 3.795px 13.282px 0px #123698 inset;
}

.gradient1{
  background: linear-gradient(180deg, rgba(17, 39, 76, 0.00) 0%, #11274C 32.43%);
}


.gradient-border {
  border: double 1px transparent;
  border-radius: 40px;
  background-image: linear-gradient(#25467B, #25467B), 
                    linear-gradient(to top, #25467B, #ffffffac);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;
}

.gradient-border-contact {
border: solid 2px transparent;
  background-image: linear-gradient(#01396A, #01396A), 
                    linear-gradient(20deg, #01396A, #ffffffac);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.active > svg {
  /* color: #0091F7 !important; */
}

@media screen and (min-width: 768px){
  .eclip{
   top: calc(100vh - 340px) !important;
  }
.sidenav:hover::after,
  .active:after{
    content: '';
    position:  absolute;
    background-color: #0091F7;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    top: -15px;
    left: -18px;
    filter: blur(10px);
    opacity: 0.5;
  }

  .admin-nav > .sidenav:hover::after,
  .admin-nav >.active:after{
    content: '';
    position:  absolute;
    border-radius: 50%;
    height: 0px;
    width: 0px;
    top: -8px;
  }

  .admin-nav > .active::before{
    content: '';
    position: absolute;
    height: 40px !important;
    width: 40px !important;
    background-color: #0091F7;
    top: 0px;
    left: -60px;
    border-radius: 4px
  }
  
  .active::before{
    content: '';
    position: absolute;
    height: 40px;
    width: 20px;
    background-color: #0091F7;
    top: 0px;
    left: -43px;
    border-radius: 4px
  }
  
  
}



.sidenav:hover{
  color: white !important;
}

.sidenav:hover .sidenav>svg {
  color: red !important;
}

.active{
  color: white !important;
}

.sidenav:hover,
.active{
  position: relative;
}



.shape{
  clip-path: polygon(0 56%, 100% 0, 100% 100%, 0% 100%);
}

.clientReview{
  filter: drop-shadow(2px 2px 5px #0091F7);
}

.star-container{
  padding: 0px !important;
}

.game{
  transition: .5s ease-in;
}

.game:hover{
  transform: scale(0.98);
}


.slider::-webkit-scrollbar {
  display: none;
}

.lang:hover .lang>div:nth-of-type(2){
  display: flex !important;
}


.game-rank-border{
  border: solid 1px transparent;
  border-radius: 18px;
  background-image: linear-gradient(#01396A, #01396A), 
                    linear-gradient(270deg, #01396A, #ffffffac);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@media screen and (max-width:768px) {
  .game-rank-border{
    border-radius: 12px;
  }
  .Toastify__toast-theme--dark{
    width: 90% !important;
    transform: translateX(5%) !important
  }
  .input:focus ~ .label,
.input:not(:placeholder-shown).input:not(:focus)  ~.label{
  font-size: 10px !important;
  top: -3px;
}
}



/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #11274C ;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #11274C;
    border-radius: 10px;
  }

  .Toastify__toast-theme--dark{
    background: #11274C !important;
  }
  .Toastify__toast-body{
    font-family: 'Sansation' !important;
    text-transform: capitalize !important;
  }


  .admin{
    background: linear-gradient(145deg, #11427c 50%, #11427c 16%, #11274c 40%);
  }

  .glass{
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 0px );
    -webkit-backdrop-filter: blur( 0px );
    border: 1px solid rgba( 255, 255, 255, 0.58 );
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.css-1dimb5e-singleValue{
  color: 'white' !important
}

.popupopens {
  animation: open 0.5s ease 0s 1 normal forwards;
}


@keyframes open {
	0% {
		opacity: 0;
		transform: translateY(-250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.popupclose{
  animation: close 0.5s ease 0s 1 normal forwards;
}


@keyframes close {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(250px);
	}
}


@media screen and (min-width:1900px) {
  .sec{
    margin-top: -700px !important;
  }
}







input[type=checkbox] {
  position: relative;
	border: 2px solid #ffffff9f;
	border-radius: 2px;
	background: none;
	cursor: pointer;
	line-height: 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 18px;
	width: 18px;
	-webkit-appearance: none;
  opacity: .5;
}

input[type=checkbox]:hover {
  opacity: 1;
}

input[type=checkbox]:checked {
  background-color: #50c1f5ae;
  opacity: 1;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 8px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}


/* Hide the default radio input */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}

/* Style the radio input when checked */
input[type="radio"]:checked {
  background-color: #50c1f5ae;
}

.css-1f4iha7-control{
  border: none !important;
  box-shadow: none !important;
}

.question:hover ~ p {
  display: flex !important;
}

.tag-wrapper, .ReactTags__tag{
  background-color: #0090f785;
  padding: 4px 6px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ReactTags__tagInputField{
  /* background: rgba( 255, 255, 255, 0.01 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 0px ); */
  background-color: transparent;
  width: 100% !important;
  border-radius: 10px;
  padding: 14px;
  outline: none;
  color: rgba(255, 255, 255, 0.802);
}
.ReactTags__tagInput {
  width: 100%;
  
}

.ReactTags__selected{
  display: flex;
  flex-direction:  row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  
}

/* .admin-x > .ReactTags__tags > .ReactTags__selected > .ReactTags__tagInput{
  background: #ffffff11 !important;
  border: none !important;
  border-radius: 18px;
}

.admin > .ReactTags__tags > .ReactTags__selected > .ReactTags__tagInput > input{
  border: 1px solid rgba(255, 255, 255, 0.115) !important;
} */


.feature:hover ~ div{
  opacity: 1 !important;
}