.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 15px;
    z-index: 0;
  }
  
  .carousel-items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  
  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;

  }